var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{staticClass:"mb-0",attrs:{"footer-tag":"footer"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('b-button-loading',{attrs:{"text":"AGREGAR","type":"submit","processing":_vm.processing}})]},proxy:true}])},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"name"}},[_c('strong',[_vm._v("RAZÓN SOCIAL")])]),_c('validation-provider',{attrs:{"rules":"required","name":"razón social"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","placeholder":"Ingrese razón social ...","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"md":"4"}},[_c('label',{attrs:{"for":"ruc"}},[_c('strong',[_vm._v("RUC")])]),_c('validation-provider',{attrs:{"rules":"required","name":"RUC"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ruc","placeholder":"Ingrese el RUC ...","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.formData.ruc),callback:function ($$v) {_vm.$set(_vm.formData, "ruc", $$v)},expression:"formData.ruc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"md":"4"}},[_c('label',{attrs:{"for":"businessArea"}},[_c('strong',[_vm._v("RUBRO")])]),_c('validation-provider',{attrs:{"rules":"required","name":"rubro"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"businessArea","placeholder":"Seleccione","state":errors.length > 0 ? false : null,"options":_vm.options.businessAreas},on:{"input":_vm.changeBusinessArea},model:{value:(_vm.formData.businessArea),callback:function ($$v) {_vm.$set(_vm.formData, "businessArea", $$v)},expression:"formData.businessArea"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.formData.businessArea && _vm.formData.businessArea.value === _vm.MINA)?_c('b-col',{staticClass:"mb-2",attrs:{"md":"4"}},[_c('label',{attrs:{"for":"operationUnit"}},[_c('strong',[_vm._v("UNIDAD DE OPERACIÓN")])]),_c('validation-provider',{attrs:{"rules":_vm.formData.businessArea.value === _vm.MINA ? 'required' : '',"name":"rubro"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"operationUnit","placeholder":"Seleccione","state":errors.length > 0 ? false : null,"options":_vm.options.operationUnits},model:{value:(_vm.formData.operation_unit),callback:function ($$v) {_vm.$set(_vm.formData, "operation_unit", $$v)},expression:"formData.operation_unit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2230981380)})],1):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }