<template>
	<validation-observer ref="refForm">
		<b-form
			@submit.prevent="onSubmit"
			@reset.prevent="resetForm"
		>
			<b-row>
				<b-col md="12">
					<b-card
						footer-tag="footer"
						class="mb-0"
					>
						<b-row>
							<!-- PROVIDER NAME -->
							<b-col
								cols="12"
								class="mb-2"
							>
								<label for="name">
									<strong>RAZÓN SOCIAL</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="razón social"
								>
									<b-form-input
										id="name"
										v-model="formData.name"
										placeholder="Ingrese razón social ..."
										:state="errors.length > 0 ? false : null"
										trim
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- RUC -->
							<b-col
								md="4"
								class="mb-2"
							>
								<label for="ruc">
									<strong>RUC</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="RUC"
								>
									<b-form-input
										id="ruc"
										v-model="formData.ruc"
										placeholder="Ingrese el RUC ..."
										:state="errors.length > 0 ? false : null"
										trim
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- BUSINESS AREA -->
							<b-col
								md="4"
								class="mb-2"
							>
								<label for="businessArea">
									<strong>RUBRO</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="rubro"
								>
									<v-select
										id="businessArea"
										placeholder="Seleccione"
										v-model="formData.businessArea"
										@input="changeBusinessArea"
										:state="errors.length > 0 ? false : null"
										:options="options.businessAreas"
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<b-col
								v-if="formData.businessArea && formData.businessArea.value === MINA"
								md="4"
								class="mb-2"
							>
								<label for="operationUnit">
									<strong>UNIDAD DE OPERACIÓN</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									:rules="formData.businessArea.value === MINA ? 'required' : ''"
									name="rubro"
								>
									<v-select
										id="operationUnit"
										placeholder="Seleccione"
										v-model="formData.operation_unit"
										:state="errors.length > 0 ? false : null"
										:options="options.operationUnits"
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- START DATE CYCLE -->
							<!-- <b-col
								md="4"
								class="mb-2"
							>
								<label for="nextDateCycle">
									<strong>PRÓXIMA FECHA DEL CICLO</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="próxima fecha del ciclo"
								>
									<b-input-group>
										<flat-pickr
											id="nextDateCycle"
											v-model="formData.nextDateCycle"
											:state="errors.length > 0 ? false : null"
											class="form-control"
											:config="onlyDateConfig"
											placeholder="YYYY-MM-DD"
										/>
										<b-input-group-append>
											<b-button
												variant="primary"
												@click="clearDate('nextDateCycle')"
											>
												<feather-icon
													icon="RefreshCwIcon"
													size="12"
												/>
											</b-button>
										</b-input-group-append>
									</b-input-group>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>-->
						</b-row>

						<template #footer>
							<!-- BUTTON SUBMIT -->
							<b-button-loading
								text="AGREGAR"
								type="submit"
								:processing="processing"
							></b-button-loading>
						</template>
					</b-card>
				</b-col>
			</b-row>
		</b-form>
	</validation-observer>
</template>

<script>
import useNotifications from "@notifications"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import { ref, onBeforeMount, onUnmounted } from "@vue/composition-api"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import providerStoreModule from "../providerStoreModule"
import { Spanish } from "flatpickr/dist/l10n/es"
import flatPickr from "vue-flatpickr-component"
import { required } from "@validations"
import vSelect from "vue-select"
import router from "@/router"
import moment from "moment"
import store from "@/store"
import axios from "@axios"

export default {
	components: {
		ValidationProvider,
		ValidationObserver,

		BButtonLoading,

		flatPickr,
		required,
		vSelect
	},
	setup() {
		// USE TOAST
		const { toastNotification } = useNotifications()

		// REFS
		const refForm = ref(null)
		const MINA = ref(1)

		const onlyDateConfig = ref({
			enableTime: false,
			locale: Spanish,
			minDate: moment().format("YYYY-MM-DD")
		})

		const formData = ref({
			name: null,
			ruc: null,
			businessArea: null,
			operation_unit: null
		})

		const processing = ref(false)
		const options = ref({})

		const PROVIDER_APP_STORE_MODULE_NAME = "app-provider"

		// REGISTER MODULE
		if (!store.hasModule(PROVIDER_APP_STORE_MODULE_NAME))
			store.registerModule(PROVIDER_APP_STORE_MODULE_NAME, providerStoreModule)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(PROVIDER_APP_STORE_MODULE_NAME))
				store.unregisterModule(PROVIDER_APP_STORE_MODULE_NAME)
		})

		// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
		onBeforeMount(() => {
			getSelectFilters()
		})

		const getSelectFilters = async () => {
			const response = await axios.get("/selectors/business-areas")
			const operationUnits = [
				{ label: "SANTA MARÍA", value: "SANTA MARÍA" },
				{ label: "MARAÑÓN", value: "MARAÑÓN" }
			]
			options.value = {
				...response.data,
				operationUnits
			}
		}

		const resetFormData = () => {
			formData.value = {
				name: null,
				ruc: null,
				businessArea: null,
				operation_unit: null
			}
		}

		const clearDate = (index) => {
			formData.value[index] = null
		}

		const changeBusinessArea = (e) => {
			formData.value.operation_unit = null
		}

		const onSubmit = async () => {
			const isValid = await refForm.value.validate()

			if (!isValid) {
				toastNotification("Complete los campos requeridos.", false)
				return
			}

			processing.value = true

			const payload = {
				name: formData.value.name,
				ruc: formData.value.ruc,
				business_area_id: formData.value.businessArea.value,
				operation_unit: formData.value.operation_unit?.value || null
			}

			store
				.dispatch("app-provider/createProvider", payload)
				.then((response) => {
					toastNotification(response.data.message)

					resetFormData()
					refForm.value.reset()

					router.push({ name: "providers-list" })
				})
				.catch((err) => {
					const message =
						err.response.data.message || "Error al agregar proveedor"
					toastNotification(message, false)
				})
				.finally(() => (processing.value = false))
		}

		return {
			// DATA
			onlyDateConfig,
			processing,
			formData,
			options,

			// REFS
			refForm,
			MINA,

			// METHODS
			clearDate,
			changeBusinessArea,
			onSubmit
		}
	}
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>